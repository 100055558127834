import React from 'react';
import PropTypes from 'prop-types';

const ToastMessage = ({positions, positionId}) => (
    <div>
        {`The following direct reports for Position ID ${positionId} have been impacted:`}
        <ul>
            {positions.map(item => {
                return <li key={item.position_id} style={{marginBottom:'2px'}}>Position ID: {item.position_id}</li>;
            })}
        </ul>
    </div>
);

ToastMessage.propTypes = {
    positions: PropTypes.array,
    positionId:PropTypes.number
};

export default ToastMessage;