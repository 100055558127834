import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import Admin from './pages/admin/Admin';
import Main from './pages/main/Main';
import Home from './pages/home/Home';
import Dashboard from './pages/dashboard/Dashboard';
import Initiatives from './pages/initiatives/Initiatives';
import InitiativeForm from './pages/initiativeForm/InitiativeForm';
import InitiativeFormNavigate from './pages/initiativeForm/InitiativeFormNavigate';
import MyResources from './pages/myResources/MyResources';
import DashboardNav from './pages/dashboard/DashboardNav';
import ResourceForm from './pages/resourceForm/ResourceForm';
import PositionForm from './pages/positionForm/PositionForm';
import { getBaseTspmCoreURL } from './utils/getBaseUrls';
import { useDispatch } from 'react-redux';
import { setUser } from './redux/features/userSlice';
import AuthUser from './components/AuthUser';
import LoadingAnimation from './components/common/loadingAnimation/LoadingAnimation';
import PositionFormNavigate from './pages/positionForm/PositionFormNavigate';
import { getRequest } from './services/axiosClient';
import PowerBi from './pages/dashboard/PowerBi';
import DownloadRmrData from './pages/dashboard/DownloadRmrData';
import NotFound from './pages/404/NotFound';



function App() {
    const dispatch = useDispatch();
    const baseUrl = getBaseTspmCoreURL();

    /* COMPONENT STATE */
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [errorStatus, setErrorStatus] = useState({ apiError: false, statusCode: 200 });
    const [isAdmin, setIsAdmin] = useState(false);

    /**
     * Fetches the user's authentication status and admin status.
     * Makes two GET requests to the API and sets component state accordingly.
     * If an error occurs, sets the errorStatus state with the error details.
     */
    const getAuthStatus = async () => {
        try {
            const res = await getRequest(baseUrl + '/api/me/false');
            const resAdmin = await getRequest(baseUrl + '/api/datasrcs/1/schemas/tspmcfg/tables/security_group/records?azure_ad_object_desc=ITS-APP-TSIS-RMR-ADMIN');

            const adminKey = resAdmin.data.data[0].azure_ad_object;
            const adminStatus = res.data.user.groups.includes(adminKey);

            dispatch(setUser({ user: res.data.user, isAdmin: adminStatus }));
            setIsAdmin(adminStatus);
            setIsAuthenticated(true);
        } catch (error) {
            setErrorStatus({ apiError: true, statusCode: error.request?.status, message: error.response?.data?.error });
        }
    };

    /* Fetches the user's authentication and admin status when the component mounts. */
    useEffect(() => {
        getAuthStatus();
    }, []);

    const dashboardRoutes = (
        <Route path="dashboard" element={<Dashboard isAdmin={isAdmin} />}>
            <Route index element={<Navigate to={'financialRecovery'} />} />
            <Route path=":linkId" element={<DashboardNav />} />
            <Route path="v_rmr_project_id_detailed_data_api" element={<DownloadRmrData />} />
            {isAdmin && <Route path="powerBi" element={<PowerBi />} />}
        </Route>
    );

    const adminRoutes = isAdmin && (
        <>
            <Route path="admin" element={<Admin />} />
            <Route path="positionForm" element={<PositionFormNavigate />}>
                <Route path=":positionId" element={<PositionForm />} />
            </Route>
        </>
    );

    const mainRoutes = (
        <Routes>
            <Route path="/" element={<Main />}>
                <Route path="*" element={<NotFound/>}/>
                <Route index element={<Home />} />
                {dashboardRoutes}
                <Route path="initiative" element={<Initiatives />} />
                <Route path="initiativeForm" element={<InitiativeFormNavigate />} >
                    <Route path=":initiativeId" element={<InitiativeForm />} />
                </Route>
                <Route path="updateMyResources" element={<div><Outlet /></div>} >
                    <Route path="wwid/:wwid" element={<MyResources type={'wwid'} />} />
                    <Route path="positionid/:positionid" element={<MyResources type={'positionid'} />} />
                </Route>
                <Route path="resourceForm" element={<div><Outlet /></div>} >
                    <Route path=":wwid" element={<ResourceForm />} />
                </Route>
                {adminRoutes}
            </Route>
        </Routes>
    );

    const loadingSpinner = !errorStatus.message ? (
        <div className='main-loading-spinner'>
            <LoadingAnimation />
        </div>
    ) : null;


    return (
        <div className="App">
            {errorStatus.apiError && <AuthUser error={errorStatus} />}
            {isAuthenticated ? mainRoutes : loadingSpinner}
        </div>
    );
}

export default App;
