import axios from 'axios';
import { setUnauthorized } from '../redux/features/rmrStateSlice';
import store from '../redux/store';
const axiosClient = axios.create();

// setting default headers
axiosClient.defaults.headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
};


//All request will wait 2 seconds before timeout
axiosClient.defaults.timeout = 20000;

axiosClient.defaults.withCredentials = true;

axiosClient.interceptors.response.use(function (response) {
    //Dispatch any action on success
    return response;
}, function (error) {
    const error_code_unauthorized = 401;
    if (error.response.status === error_code_unauthorized) {
        //handling unauthrized status
        store.dispatch(setUnauthorized(true));
        return Promise.reject(error);
    }
    return Promise.reject(error);
});

export function getRequest(URL) {
    return axiosClient.get(`${URL}`).then(response => response);
}

export function postRequest(URL, payload) {
    return axiosClient.post(`${URL}`, payload).then(response => response);
}

export function putRequest(URL, payload) {
    return axiosClient.put(`${URL}`, payload).then(response => response);
}

export function deleteRequest(URL) {
    return axiosClient.delete(`${URL}`).then(response => response);
}