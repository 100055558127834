import React,{useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { getRequest } from '../../services/axiosClient';
import { getBaseTspmCoreURL } from '../../utils/getBaseUrls';
import { useSelector, useDispatch } from 'react-redux';
import { setPositionConfig, positionConfig, positionOptions, setPosOptions } from '../../redux/features/positionFormSlice';
import PositionFormProvider from './PositionFormProvider';
import './position.css';
import LoadingAnimation from '../../components/common/loadingAnimation/LoadingAnimation';
import ErrorStatus from '../../components/common/ErrorStatus';
import logPageView from '../../utils/logPageView';
import { backendUrls } from '../../utils/constants';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
const positionUrls = {
    rmr_pay_grade: backendUrls.rmr_pay_grade + '?active_flag=true',
    rmr_bravo_reportable: backendUrls.rmr_bravo_reportable + '?active_flag=true',
    rmr_ldp: backendUrls.rmr_ldp + '?active_flag=true',
    rmr_country: backendUrls.rmr_country + '?active_flag=true',
    rmr_region: backendUrls.rmr_region + '?active_flag=true',
    rmr_legacy: backendUrls.rmr_legacy + '?active_flag=true',
    rmr_disposition: backendUrls.rmr_disposition + '?active_flag=true',
    rmr_type: backendUrls.rmr_type + '?active_flag=true',
    rmr_chapterarea_platformgroup: backendUrls.rmr_chapterarea_platformgroup + '?active_flag=true',
    rmr_chapter_platform : backendUrls.rmr_chapter_platform + '?active_flag=true',
    rmr_subchapter: backendUrls.rmr_subchapter + '?active_flag=true',
    rmr_cost_center: backendUrls.rmr_cost_center + '?active_flag=true',
};



const PositionForm = () => {
    const params = useParams();
    const positionId =  params.positionId !== 'new' ? parseInt(params.positionId): null;
    const baseUrl = getBaseTspmCoreURL();
    const dispatch = useDispatch();
    const posConfig = useSelector(positionConfig);

    const positionOpt = useSelector(positionOptions);
    const [error, setError] = useState({status:false, message:''});
    const [success, setSuccess] = useState(false);
    const defaultPosition = {name:'', position_status_id:'', comments:'',headcount_request_number:'',dependent_position_id:'', dependent_tag_id:''};
    const defaultSupPosition = {position_id: positionId,parent_position_id: '', start_date: '', end_date: null};
    const defaultPosResource = {position_id: positionId, resource_wwid: '', start_date:'', end_date:null,};
    const defaultPayGrade = {position_id: positionId, pay_grade_id: '', start_date:'', end_date:null,};
    const defaultBravoRep = {position_id: positionId, bravo_reportable_id: '',  start_date:'', end_date:null,};
    const defaultLdp = {position_id: positionId, ldp_id: '', start_date:'', end_date:null,};
    const defaultLoc = { position_id: positionId, region_id: 1, country_id:'', start_date:'', end_date:null,};
    const defaultDis= {position_id: positionId, legacy_id: 1, disposition_id: '', function_id: '', app_dev_recov_tgt_pct: 0, am_recov_tgt_pct: 0, start_date: '', end_date: null};
    const defaultCost = {position_id: positionId, cost_center_id: '', start_date:'', end_date:null,};

    /* COMPONENT STATE */
    const [position, setPosition] = useState({fetched:false, 
        data:defaultPosition, 
        resPosData:[defaultPosResource], 
        resSupPos:[defaultSupPosition], 
        payGradeData:[defaultPayGrade], 
        bravoRepData:[defaultBravoRep], 
        ldpData:[defaultLdp], 
        locationData:[defaultLoc],
        dispositionData:[defaultDis],
        costData:[defaultCost]
    });

    const getPositionData = async () => {
        setPosition({...position, fetched:false});
        const urls = {
            data: backendUrls.rmr_position + `?display-value=true&id=${params.positionId}`,
            resPosData: backendUrls.rmr_position_resource + `?display-value=true&position_id=${params.positionId}&sort-start_date=ASC`,
            dispositionData: backendUrls.rmr_position_disposition_function + `?position_id=${params.positionId}&sort-start_date=ASC`,
            resSupPos: backendUrls.rmr_position_hierarchy + `?position_id=${params.positionId}&sort-start_date=ASC`,
            locationData: backendUrls.rmr_position_location + `?position_id=${params.positionId}&sort-start_date=ASC`,
            payGradeData: backendUrls.rmr_position_pay_grade +  `?position_id=${params.positionId}&sort-start_date=ASC`,
            bravoRepData: backendUrls.rmr_position_bravo_reportable + `?position_id=${params.positionId}&sort-start_date=ASC`,
            ldpData: backendUrls.rmr_position_ldp +  `?position_id=${params.positionId}&sort-start_date=ASC`,
            costData: backendUrls.rmr_position_cost_center + `?position_id=${params.positionId}&sort-start_date=ASC`,
            
        };
        const fetchData = async key => {
            const response = await getRequest(baseUrl + urls[key]);
            return [key, response.data.data];
        };

        try {
            const results = await Promise.all(Object.keys(urls).map(key => fetchData(key)));
            const fetchedData = { fetched: true };

            results.forEach(([key, data]) => {
                if (key === 'data') {
                    fetchedData[key] = data[0];
                } else {
                    fetchedData[key] = data;
                }
            });
        
            setPosition(fetchedData);
    
        } catch (err) {
            const msg = err?.request?.statusText ? ` - ${err?.request?.statusText}!` : '!';
            setError({
                status: true,
                message: 'Error with getting position form data, please refresh page or try again later' + msg,
            });
        }
    };
    
    
    //getting select options of position form fields
    const getPositionOptions = async () => {
        const fetchTableData = async table => getRequest(baseUrl + positionUrls[table]);
    
        try {
            const results = await Promise.all(
                Object.keys(positionUrls).map(async table => {
                    const response = await fetchTableData(table);
                    return [table, response.data.data];
                })
            );
    
            const fetchedData = { fetched: true };
    
            results.forEach(([table, data]) => {
                fetchedData[table] = data;
            });
    
            dispatch(setPosOptions(fetchedData));
    
        } catch (err) {
            dispatch(setPosOptions({
                fetched: false,
                ...Object.keys(positionUrls).reduce((acc, table) => ({ ...acc, [table]: [] }), {}),
            }));
            
            const msg = err?.request?.statusText ? ` - ${err?.request?.statusText}!` : '!';
            setError({
                status: true,
                message: 'Error with getting position table data, please refresh page or try again later' + msg,
            });
        }
    };
    
    
    // getting position table config information
    const getConfig = async() => { 
        try {
            const resConfig = await getRequest(baseUrl + '/api/datasrcs/1/schemas/rmr/tables/rmr_position/config'); 
            dispatch(setPositionConfig({fetched:true, data: resConfig.data.columnConfig}));
        } catch(err) {
            /* 
                 setting api error 
                 results
                */
            const msg = err?.request.statusText ? ` - ${err?.request.statusText}!` : '!';
            setError({status:true, message:'Error with getting position table config data, please refresh page or try again later' + msg});
        }
    };

    useEffect(()=>{

        //get position data if position id isnot null
        if (!position.fetched && params.positionId !== 'new') {
            getPositionData();
        }
        // fetch position config if not fetched
        if(!posConfig.fetched) {
            getConfig();
        }
        // fetch position options if not fetched
        if(!positionOpt.fetched){
            getPositionOptions();
        }
    },[params.positionId]);
  
    /* 
     logging page vieww data
    */
    useEffect(()=>{
        logPageView();
    },[]);


   
    useEffect(() => {
        /* 
            Clearing the message 
            after 10 second. 
        */
        const delay = 10000;
        const timeout = setTimeout(() => {
            setSuccess(false);
        }, delay);
        
        /* 
            A cleanup function. 
            It is called when the component is unmounted. 
        */
        return () => {
            clearTimeout(timeout);
        };
    }, [success]);


    /* 
        Render form with different params
        when id is new(new record)
    */
    if(params.positionId === 'new'){
        return (
            <div>
                <div className='form-header'>Create New Position</div>
                <ErrorStatus error={error} setError={setError}/>
                {success && <span style={{color:'green'}}>Your changes have been successfully saved!</span>}
                <ToastContainer className='test-class' style={{width:'100%', maxWidth:400, top:100}}/>
                {posConfig.fetched && positionOpt.fetched ? 
                    <PositionFormProvider 
                        position={position.data} 
                        config={posConfig.data} 
                        positionId={positionId}
                        resourcePosData={position.resPosData}
                        supervisorPosData={position.resSupPos}
                        payGradeData={position.payGradeData}
                        bravoRepData={position.bravoRepData}
                        ldpData={position.ldpData}
                        locationData={position.locationData}
                        dispositionData={position.dispositionData}
                        costData={position.costData}
                        setSuccess={setSuccess}
                    /> : <LoadingAnimation type={'dot'}/>}
            </div>
        );
    }

    /* 
        Render form with different params
        when id present
    */

    return (
        <div>
            <div className='form-header'>Update Position ID: {params.positionId}</div>
            <ErrorStatus error={error} setError={setError}/>
            <ToastContainer className='test-class' style={{width:'100%', maxWidth:400, top:100}}/>
            {success && <span style={{color:'green'}}>Your changes have been successfully saved!</span>}
            {posConfig.fetched && positionOpt.fetched && position.fetched ? 
                <PositionFormProvider 
                    position={position.data} 
                    config={posConfig.data} 
                    positionId={params.positionId} 
                    getPositionData={getPositionData} 
                    resourcePosData={position.resPosData}
                    supervisorPosData={position.resSupPos}
                    payGradeData={position.payGradeData}
                    bravoRepData={position.bravoRepData}
                    ldpData={position.ldpData}
                    locationData={position.locationData}
                    dispositionData={position.dispositionData}
                    costData={position.costData}
                    setSuccess={setSuccess}
                /> : <LoadingAnimation type={'dot'}/>}
        </div>
    );
};

export default PositionForm;